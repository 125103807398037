<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <div id="visualizar-mapa-desenhar-poligonos"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import L from 'leaflet';
import wms from 'leaflet.wms';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import BasesDadosService from '@/services/BasesDadosService';
import GeoServerService from '@/services/GeoServerService';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';
import scaleMixins from '@/mixins/leaflet/scaleMixins';
import drawMixins from '@/mixins/leaflet/drawMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  name: 'FormDesenharPoligonosConfig',

  mixins: [geocoderMixins, scaleMixins, drawMixins, filtersMixins],

  props: {
    month: {
      type: String
    },
    bdgdVersion: {
      type: Number
    },
    algoritmoConfig: {
      type: Object
    }
  },

  data() {
    return {
      poligonos: {},
      map: null,
      layerControl: null,
      mapaId: 'visualizar-mapa-desenhar-poligonos',
      bases: [],
      layers: {},
      drawControls: []
    };
  },

  mounted() {
    this.configuraMapa(4);

    // Coloca o centro do mapa próximo a capital onde está a distribuidora
    let [longitude, latitude] =
      this.$store.getters.getCompanyCapitalCoordinates;

    this.setView(latitude, longitude);

    this.map.setZoom(18);

    this.getBasesDados(this.month, this.bdgdVersion);
  },

  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    },

    cqlFilter() {
      return this.companyId && this.month && this.bdgdVersion
        ? this.getBasicCqlFilter(this.companyId, this.month, this.bdgdVersion)
        : this.getBasicCqlFilter(this.companyId);
    },

    ogcFilter() {
      return this.companyId && this.month && this.bdgdVersion
        ? this.getBasicOgcFilter(this.companyId, this.month, this.bdgdVersion)
        : this.getBasicOgcFilter(this.companyId);
    }
  },

  watch: {
    cqlFilter() {
      this.addBasesLayers();
    },

    month(newMonth) {
      if (newMonth && this.bdgdVersion)
        this.getBasesDados(newMonth, this.bdgdVersion);
    },

    bdgdVersion(newBdgdVersion) {
      if (newBdgdVersion && this.newMonth)
        this.getBasesDados(this.newMonth, newBdgdVersion);
    },

    poligonos() {
      this.algoritmoConfigAtualizado();
    }
  },

  methods: {
    getBasesDados(month, bdgdVersion) {
      BasesDadosService.getBasesDadosGeograficasLiberadas(
        this.companyId,
        month,
        bdgdVersion
      )
        .then((response) => {
          this.bases = response.data;
          this.limparBasesLayers();
          this.addBasesLayers();

          if (!this.bases.length) {
            this.$toast.warning(
              'Não existe nenhuma entidade geografica disponível para esse mês e versão da BDGD escolhida.',
              '',
              { position: 'topRight' }
            );
          }
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao recuperar bases de dados para layers.',
            '',
            { position: 'topRight' }
          );
        });
    },

    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        editable: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });

      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);

      this.createScaleControl(L).addTo(this.map);
      this.createGeocoderControl(L, this.map).addTo(this.map);

      if (this.algoritmoConfig) {
        this.drawMapEdit();
      } else {
        this.configDrawControl(L);
      }
    },

    configDrawControl(L, polygon) {
      let { drawnItems, drawControl } = this.createDrawControl(L);

      if (polygon && polygon.length > 0) {
        for (let item of polygon) {
          drawnItems.addLayer(item);
        }
      }

      this.map.addLayer(drawnItems);
      this.map.addControl(drawControl);

      this.map.on('draw:created', ({ layer }) => {
        drawnItems.addLayer(layer);

        const key = `poligono-${layer._leaflet_id}`;

        this.$set(this.poligonos, key, {
          layerId: layer._leaflet_id,
          coordinates: layer.getLatLngs()
        });
      });

      this.map.on('draw:edited', ({ layers }) => {
        layers.eachLayer((layer) => {
          const key = `poligono-${layer._leaflet_id}`;

          this.$set(this.poligonos, key, {
            layerId: layer._leaflet_id,
            coordinates: layer.getLatLngs()
          });
        });
      });

      this.map.on('draw:deleted', ({ layers }) => {
        layers.eachLayer((layer) => {
          const key = `poligono-${layer._leaflet_id}`;
          this.$delete(this.poligonos, key);
        });
      });
    },

    setView(latitude = -2.5606242, longitude = -44.4016494) {
      this.map.setView([latitude, longitude]);
    },

    drawMapEdit() {
      let polygons = this.algoritmoConfig.poligonos;
      this.poligonos = polygons;
      let polygonsArray = [];
      for (const item in polygons) {
        let polygon = L.polygon(polygons[item].coordinates).addTo(this.map);
        const itemRenamed = `poligono-${polygon._leaflet_id}`;
        this.poligonos[itemRenamed] = this.poligonos[item];
        delete this.poligonos[item];
        polygonsArray.push(polygon);
      }
      this.configDrawControl(L, polygonsArray);
    },

    limparBasesLayers() {
      Object.keys(this.layers).forEach((nomeTabela) => {
        this.layerControl.removeLayer(this.layers[nomeTabela]);
      });
    },

    addBasesLayers() {
      this.bases.forEach((base) => this.addBaseLayer(base));
    },

    addBaseLayer(base) {
      const geoserverBaseUrl = GeoServerService.getGeoServerBaseUrl();
      const baseUrl = GeoServerService.getWmsBaseUrl();
      const workspace = GeoServerService.getWorkspaceDefault();

      // Se o layer já existe, remove-o
      if (this.layers[base.nome_tabela]) {
        this.layerControl.removeLayer(this.layers[base.nome_tabela]);
      }

      const ignoredProperties = [
        'GEOM',
        'CONTADOR',
        'COMPANY',
        'COMPANY_ID',
        'MES_DADOS',
        'BDGD_VERSAO',
        'BDGD_VERSAO_ID'
      ];

      const MySource = wms.Source.extend({
        showFeatureInfo: (latlng, info) => {
          if (info.includes('no features were found')) {
            return;
          }

          let arrInfo = info.split('\n');
          let stringInfo = '';

          arrInfo.forEach((element, key) => {
            if ([0, 1, arrInfo.length - 1, arrInfo.length - 2].includes(key))
              return;

            if (element.slice(-4) == 'null') return;

            element = element.split('=');
            let property = element[0].trim().toUpperCase();
            if (ignoredProperties.includes(property)) return;

            element = property + ': ' + element[1].trim();
            stringInfo += `${element}<br>`;
          });

          L.popup()
            .setLatLng(latlng)
            .setContent(`<div id="div-popup">${stringInfo}</div>`)
            .openOn(this.map);
        }
      });

      let config = {
        format: 'image/png',
        transparent: true,
        identify: true
      };

      this.isCqlFilter()
        ? (config.cql_filter = this.cqlFilter)
        : (config.filter = this.ogcFilter);

      // Cria o layer
      let source = new MySource(`${geoserverBaseUrl}/${baseUrl}`, config);
      this.layers[base.nome_tabela] = source.getLayer(
        `${workspace}:${base.nome_tabela}`
      );

      // Adiciona o layer no mapa
      this.layerControl.addOverlay(
        this.layers[base.nome_tabela],
        base.descricao
      );
    },

    algoritmoConfigAtualizado() {
      this.$emit('algoritmoConfigAtualizado', {
        poligonos: this.poligonos
      });
    }
  }
};
</script>

<style>
#visualizar-mapa-desenhar-poligonos {
  height: 500px;
  z-index: 0;
}

#div-popup {
  max-height: 200px;
  overflow-y: auto;
}
</style>
